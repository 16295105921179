import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container, Svg } from './ui';
import ProductFilters from './ProductFilters';
import ScrollableContent from './ScrollableContent';
import ProductCard from './ProductCard';
import filterIcon from '../images/filter-icon.inline.svg';

const StyledProductListing = styled.section`
  margin-bottom: 40px;

  ${minBreakpointQuery.small`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1fr 2.8fr;
    align-items: baseline;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 1fr 3.2fr;
  `}
`;

const StyledContentItems = styled.div``;

const StyledRefineSort = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  gap: 15px;

  ${minBreakpointQuery.xxlarge`
    margin-top: 25px;
    margin-bottom: 25px;
  `}
`;

const StyledRefine = styled.button`
  ${maxBreakpointQuery.mlarge`
    padding: 10px 16px 9px;
    color: ${standardColours.black};
    font-family: ${brandFonts.secondary};
    ${fontSize(12)};
    background-color: ${standardColours.white};
    border: 1px solid ${standardColours.grey};
    white-space: nowrap;
  `}

  ${minBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  svg {
    margin-left: 18px;
    height: 14px;
    width: 14px;
    vertical-align: middle;
  }
`;

const StyledRefinedAmount = styled.p`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    color: ${standardColours.darkerGrey};
    ${fontSize(13)};
  `}

  span {
    ${minBreakpointQuery.mlarge`
      font-weight: ${fontWeights.bold};
    `}
  }
`;

const StyledSort = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
`;

const StyledSortText = styled.span`
  ${maxBreakpointQuery.tsmall`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.tsmall`
    color: ${standardColours.darkerGrey};
    ${fontSize(12)};
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(13)};
  `}
`;

const StyledSortSelectWrapper = styled.div`
  position: relative;
  background-color: ${standardColours.white};
  border: 1px solid ${standardColours.grey};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 14px;
    display: block;
    height: 10px;
    width: 10px;
    border: 2px solid ${standardColours.darkerGrey};
    border-top: none;
    border-left: none;
    border-radius: 1px;
    pointer-events: none;
    transform: translateY(calc(-50% - 2px)) rotate(45deg);
  }
`;

const StyledSortSelect = styled.select`
  padding: 9px 35px 9px 14px;
  font-family: ${brandFonts.primary};
  ${fontSize(12)};
  background: none;
  border: none;
  appearance: none;

  ${minBreakpointQuery.mlarge`
    ${fontSize(13)};
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const ProductListing = ({
  heading,
  text,
  items: initialItems,
  isHuggleland,
}) => {
  const [displayFilters, setDisplayFilters] = useState(false);

  const [items, setItems] = useState(initialItems);

  const lowHigh = [...initialItems].sort(
    (a, b) =>
      a.priceRangeV2.minVariantPrice.amount -
      b.priceRangeV2.minVariantPrice.amount
  );

  const sortBy = e => {
    if (e.target.value === 'high-low') {
      setItems(lowHigh.reverse());
    } else if (e.target.value === 'low-high') {
      setItems(lowHigh);
    } else if (e.target.value === 'popular') {
      setItems(initialItems);
    }
  };

  return (
    <StyledProductListing>
      <Container>
        <StyledInner>
          <ProductFilters
            initialProducts={initialItems}
            setProducts={setItems}
            display={displayFilters}
            setDisplay={setDisplayFilters}
            isHuggleland={isHuggleland}
          />
          <StyledContentItems>
            <ScrollableContent heading={heading} text={text} />
            <StyledRefineSort>
              <StyledRefine onClick={() => setDisplayFilters(true)}>
                Refine Search <Svg image={filterIcon} />
              </StyledRefine>
              <StyledRefinedAmount>
                Showing <span>{items.length}</span> products
              </StyledRefinedAmount>
              <StyledSort for="sort">
                <StyledSortText>Sort by:</StyledSortText>
                <StyledSortSelectWrapper>
                  <StyledSortSelect name="sort" id="sort" onChange={sortBy}>
                    <option value="popular">Popular</option>
                    <option value="high-low">Price High to Low</option>
                    <option value="low-high">Price Low to High</option>
                  </StyledSortSelect>
                </StyledSortSelectWrapper>
              </StyledSort>
            </StyledRefineSort>
            <StyledItems>
              {items.map(item => (
                <ProductCard
                  key={item.id}
                  title={item.title}
                  handle={item.handle}
                  collections={item.collections}
                  featuredImage={item.featuredImage}
                  colour={item.colour}
                  coverMaterial={item.coverMaterial}
                  filling={item.filling}
                  weight={item.weight}
                  size={item.size}
                  threadCount={item.threadCount}
                  tags={item.tags}
                  priceRangeV2={item.priceRangeV2}
                  variants={item.variants}
                  isBestSeller={item.isBestSeller}
                  isAntiAllergy={item.isAntiAllergy}
                  isInSale={item.isInSale}
                  isRetailProduct={item.isRetailProduct}
                />
              ))}
            </StyledItems>
          </StyledContentItems>
        </StyledInner>
      </Container>
    </StyledProductListing>
  );
};

export default ProductListing;
