import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandFonts,
  fontSize,
} from '../styles';
import { HtmlContent } from './ui';

const StyledScrollableContent = styled.section`
  position: relative;
  padding: 20px;
  background-color: ${standardColours.lighterGrey};

  ${minBreakpointQuery.tiny`
    padding: 25px;
  `}

  ${minBreakpointQuery.small`
    padding: 30px;
  `}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      ${standardColours.transparent} 0%,
      ${standardColours.lighterGrey} 50%
    );

    ${minBreakpointQuery.tiny`
      height: 50px;
    `}

    ${minBreakpointQuery.small`
      height: 60px;
    `}
  }
`;

const StyledInner = styled.div`
  padding-bottom: 10px;
  height: 130px;
  overflow: auto;

  ${minBreakpointQuery.tiny`
    padding-bottom: 12.5px;
  `}

  ${minBreakpointQuery.small`
    padding-bottom: 15px;
  `}
`;

const StyledHeading = styled.h2`
  margin-bottom: 8px;
  color: ${standardColours.black};
  font-family: ${brandFonts.primary};
  ${fontSize(14)};

  ${minBreakpointQuery.tiny`
    margin-bottom: 10px;
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.small`
    margin-bottom: 12px;
    ${fontSize(18)};
  `}
`;

const StyledText = styled(HtmlContent)`
  p {
    ${fontSize(12)};
    line-height: 1.6;

    ${minBreakpointQuery.tiny`
      ${fontSize(13)};
    `}

    ${minBreakpointQuery.small`
      ${fontSize(14)};
    `}
  }
`;

const ScrollableContent = ({ heading, text }) => (
  <StyledScrollableContent>
    <StyledInner>
      <StyledHeading>{heading}</StyledHeading>
      <StyledText html={text} />
    </StyledInner>
  </StyledScrollableContent>
);

export default ScrollableContent;
