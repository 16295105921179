import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubBanner from '../components/SubBanner';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductListing from '../components/ProductListing';
import ModularBlocks from '../components/ModularBlocks';

const ProductCategoryTemplate = ({
  data: {
    datoCmsProductCategory: {
      seoMetaTags,
      title,
      shopifyCollection,
      bannerImage,
      treeParent,
      descriptionHeading,
      description,
      modularBlocks,
    },
    shopifyCollection: { products },
  },
}) => {
  const isHuggleland = shopifyCollection === 'huggleland';

  return (
    <Layout seo={seoMetaTags}>
      <main>
        <SubBanner
          heading={title}
          image={bannerImage}
          isProductCategoryPage={true}
        />
        <Breadcrumbs currentPageTitle={title} items={treeParent} />
        <ProductListing
          heading={descriptionHeading}
          text={description}
          items={products}
          isHuggleland={isHuggleland}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const ProductCategoryTemplateQuery = graphql`
  query ProductCategoryTemplateQuery(
    $id: String!
    $shopifyCollection: String!
  ) {
    datoCmsProductCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      shopifyCollection
      bannerImage {
        ...SubBannerImageFragment
      }
      treeParent {
        title
        ...LinkFragment
        treeParent {
          title
          ...LinkFragment
          treeParent {
            title
            ...LinkFragment
          }
        }
      }
      descriptionHeading
      description
      modularBlocks {
        ...ContentModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImagesGridModularBlockFragment
      }
    }
    shopifyCollection(handle: { eq: $shopifyCollection }) {
      products {
        ...ProductCardFragment
      }
    }
  }
`;

export default ProductCategoryTemplate;
