import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  zIndexLayers,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Button } from './ui';
import { slugify } from '../utils';

const StyledProductFilters = styled.aside`
  padding: 30px;
  background-color: ${standardColours.white};
  border: 1px solid ${standardColours.grey};

  ${maxBreakpointQuery.mlarge`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    transform: translateX(-100%);
    transition: ${standardTransition('transform')};
  `}

  ${({ display }) => {
    if (display) {
      return css`
        ${maxBreakpointQuery.mlarge`
          transform: translateX(0);
        `}
      `;
    }
  }}
`;

const StyledForm = styled.form``;

const StyledHeader = styled.header`
  ${maxBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledHeading = styled.p`
  color: ${standardColours.black};
  font-family: ${brandFonts.secondary};
  ${fontSize(18)};
`;

const StyledClose = styled.button`
  ${maxBreakpointQuery.mlarge`
    color: ${standardColours.black};
    ${fontSize(13)};
    background: none;
    border: none;
  `}

  ${minBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  &:after {
    ${maxBreakpointQuery.mlarge`
      content: '✕';
      position: relative;
      bottom: 1px;
      margin-left: 12px;
      font-weight: ${fontWeights.bold};
      ${fontSize(22)};
      vertical-align: middle;
    `}
  }
`;

const StyledGroups = styled.div``;

const StyledCheckboxGroup = styled.fieldset`
  margin: 28px 0 0;
  padding: 0;
  border: none;
`;

const StyledCheckboxHeading = styled.legend`
  float: left;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
  color: ${brandColours.tertiary};
  font-weight: ${fontWeights.bold};
  ${fontSize(13)};
  text-transform: uppercase;
`;

const StyledOption = styled.div`
  margin-top: 16px;
  clear: both;

  &:first-child {
    margin-top: 0;
  }
`;

// moved up for checked styling
const StyledText = styled.label`
  position: relative;
  display: block;
  padding-left: 30px;
  color: ${standardColours.black};
  ${fontSize(13)};
  line-height: 1.5;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${brandColours.tertiary};
    transition: ${standardTransition('background-color')};
  }

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 7px;
    height: 12px;
    width: 6px;
    border: 2px solid ${standardColours.transparent};
    border-top: none;
    border-left: none;
    border-radius: 1px;
    transform: rotate(45deg);
    transition: ${standardTransition('border-color')};
  }

  &:hover {
    &:after {
      border-color: ${brandColours.tertiary};
    }
  }
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledText} {
    &:before {
      background-color: ${brandColours.tertiary};
    }

    &:after {
      border-color: ${standardColours.white};
    }
  }
`;

const StyledButton = styled(Button)`
  ${maxBreakpointQuery.mlarge`
    margin-top: 40px;
    width: 100%;
  `}

  ${minBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}
`;

const ProductFilters = ({
  initialProducts,
  setProducts,
  display,
  setDisplay,
  isHuggleland,
}) => {
  const rangeFilters = [];
  const colourFilters = [];
  const coverMaterialFilters = [];
  const fillingFilters = [];
  const weightFilters = [];
  const sizeFilters = [];
  const threadCountFilters = [];

  const hugglelandRangeOrder = [
    'Electric Underblanket',
    'Electric Blankets and Throws',
    'Coverless Duvet',
    'Wearable Blanket',
    'V Shape',
    'Cuddle Cushion',
    'Kids Cuddle Cushion',
    'Bolster Pillow',
  ];

  initialProducts.forEach(
    ({ tags, colour, coverMaterial, filling, weight, size, threadCount }) => {
      if (tags.indexOf('Every Day') === 0) {
        tags.splice(tags.indexOf('Every Day'), 1, 'Everyday');
      }
      rangeFilters.push(...tags);
      colour && colourFilters.push(colour.value);
      coverMaterial && coverMaterialFilters.push(coverMaterial.value);
      filling && fillingFilters.push(filling.value);
      weight && weightFilters.push(weight.value);
      size && sizeFilters.push(size.value);
      threadCount && threadCountFilters.push(threadCount.value);
    }
  );

  const rangeFiltersSet = [...new Set(rangeFilters)];

  const filters = [
    {
      name: 'Range',
      items: isHuggleland
        ? rangeFiltersSet.sort(
            (a, b) =>
              hugglelandRangeOrder.indexOf(a) - hugglelandRangeOrder.indexOf(b)
          )
        : rangeFiltersSet,
    },
  ];

  if (colourFilters.length > 0) {
    filters.push({
      name: 'Colour',
      items: [...new Set(colourFilters)],
    });
  }

  if (coverMaterialFilters.length > 0) {
    filters.push({
      name: 'Cover Material',
      items: [...new Set(coverMaterialFilters)],
    });
  }

  if (fillingFilters.length > 0) {
    filters.push({
      name: 'Filling',
      items: [...new Set(fillingFilters)],
    });
  }

  if (!isHuggleland && weightFilters.length > 0) {
    filters.push({
      name: 'Weight',
      items: [
        ...new Set(weightFilters.sort((a, b) => parseInt(a) - parseInt(b))),
      ],
    });
  }

  if (sizeFilters.length > 0) {
    filters.push({
      name: 'Size',
      items: [...new Set(sizeFilters)],
    });
  }

  if (threadCountFilters.length > 0) {
    filters.push({
      name: 'Thread Count',
      items: [
        ...new Set(
          threadCountFilters.sort((a, b) => parseInt(a) - parseInt(b))
        ),
      ],
    });
  }

  const handleChange = e => {
    const form = e.currentTarget;
    const formData = new FormData(form);

    const selectedRanges = formData.getAll('range');
    const selectedColour = formData.getAll('colour');
    const selectedCoverMaterials = formData.getAll('cover-material');
    const selectedFillings = formData.getAll('filling');
    const selectedWeights = formData.getAll('weight');
    const selectedSizes = formData.getAll('size');
    const selectedThreadCounts = formData.getAll('thread-count');

    const filteredProducts = initialProducts.filter(
      ({ tags, colour, coverMaterial, filling, weight, size, threadCount }) => {
        return (
          (selectedRanges.length > 0
            ? selectedRanges.some(item => tags.includes(item))
            : true) &&
          (selectedColour.length > 0
            ? colour
              ? selectedColour.includes(colour.value)
              : false
            : true) &&
          (selectedCoverMaterials.length > 0
            ? coverMaterial
              ? selectedCoverMaterials.includes(coverMaterial.value)
              : false
            : true) &&
          (selectedFillings.length > 0
            ? filling
              ? selectedFillings.includes(filling.value)
              : false
            : true) &&
          (selectedWeights.length > 0
            ? weight
              ? selectedWeights.includes(weight.value)
              : false
            : true) &&
          (selectedSizes.length > 0
            ? size
              ? selectedSizes.includes(size.value)
              : false
            : true) &&
          (selectedThreadCounts.length > 0
            ? threadCount
              ? selectedThreadCounts.includes(threadCount.value)
              : false
            : true)
        );
      }
    );

    setProducts(filteredProducts.length > 0 ? filteredProducts : []);
  };

  return (
    <StyledProductFilters display={display}>
      <StyledForm onChange={e => handleChange(e)}>
        <StyledHeader>
          <StyledHeading>Refine Search</StyledHeading>
          <StyledClose type="button" onClick={() => setDisplay(false)}>
            Close
          </StyledClose>
        </StyledHeader>
        <StyledGroups>
          {filters.map(({ name, items }, id) => (
            <StyledCheckboxGroup key={id}>
              <StyledCheckboxHeading>
                {isHuggleland && name === 'Size' ? 'Dimensions' : name}
              </StyledCheckboxHeading>
              {items.map((item, id) => {
                const identifier = slugify(item);

                return (
                  <StyledOption key={id}>
                    <StyledCheckbox
                      type="checkbox"
                      id={identifier}
                      name={slugify(name)}
                      value={item}
                    />
                    <StyledText for={identifier}>{item}</StyledText>
                  </StyledOption>
                );
              })}
            </StyledCheckboxGroup>
          ))}
        </StyledGroups>
        <StyledButton
          type="button"
          alt={true}
          onClick={() => setDisplay(false)}
        >
          Show Products
        </StyledButton>
      </StyledForm>
    </StyledProductFilters>
  );
};

export default ProductFilters;
